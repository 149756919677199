import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import secondaryImg from "../images/primary-coaches-sm.jpg"
import secondaryImgMob from "../images/primary-coaches-sm-mobile.jpg"
import secondaryImgWebp from "../images/primary-coaches-sm.webp"
import secondaryImgMobWebp from "../images/primary-coaches-sm-mobile.webp"
import coachServicesImg from "../images/coach-hire-services-sm.jpg"
import coachServicesImgWebp from "../images/coach-hire-services-sm.webp"
import coachServicesImgMob from "../images/coach-hire-services-sm-mobile.jpg"
import coachServicesImgMobWebp from "../images/coach-hire-services-sm-mobile.webp"
import fleet16SeatImg from "../images/fleet-16-seat-minibus-sm.jpg"
import fleet16SeatImgWebp from "../images/fleet-16-seat-minibus-sm.webp"
import fleet33SeatImg from "../images/fleet-33-seat-coach-sm.jpg"
import fleet33SeatImgWebp from "../images/fleet-33-seat-coach-sm.webp"
import fleet49SeatImg from "../images/fleet-49-seat-coach-sm.jpg"
import fleet49SeatImgWebp from "../images/fleet-49-seat-coach-sm.webp"
import fleet70SeatImg from "../images/fleet-70-seat-coach-sm.jpg"
import fleet70SeatImgWebp from "../images/fleet-70-seat-coach-sm.webp"
import smileSvg from "../images/smile-beam-regular.svg"
import starSvg from "../images/star-solid.svg"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import CorporateCoachImg from "../images/corporate-staff-transport-sm.jpg"
import CorporateCoachImgWebp from "../images/corporate-staff-transport-sm.webp"
import wakefieldBusImg from "../images/wakefield-free-bus-sm.jpg"
import wakefieldBusImgWebp from "../images/wakefield-free-bus-sm.webp"
import NewsStrip from "../components/news-strip";
import AOS from "aos"
import 'aos/dist/aos.css';

class IndexPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Coach Hire Leeds" description="Established in 1949, Tetley's Coaches is Leeds' oldest coach company and has provided safe and reliable coach hire in Leeds, Yorkshire, and beyond for over 75 years." />
        <div className="landing-hero-leeds">
          <div className="landing-hero__inner">
            <div className="landing-hero__inner__headline">
              <p className="landing-hero__inner__primary-headline">Reliable, affordable and local</p>
              <p className="landing-hero__inner__secondary-headline">Bus and coach hire since 1949</p>
            </div>
          </div>
        </div>

        <div className="block-about-us body-bg">
          <div className="body">
            <div className="block-about-us__container">
              <h1>Coach Hire Leeds</h1>
              <h2>Providing Coach Hire for over 75 years</h2>
              <div className="gutter block-about-us__content-parent">
                <div className="block-about-us__content-left">
                  <picture className="block-about-us__hero">
                    <source media="(max-width: 499px)" type="image/webp" srcSet={secondaryImgMobWebp} />
                    <source media="(max-width: 499px)" type="image/jpg" srcSet={secondaryImgMob} />
                    <source media="(min-width: 500px)" type="image/webp" srcSet={secondaryImgWebp} />
                    <source media="(min-width: 500px)" type="image/jpg" srcSet={secondaryImg} />
                    <img src={secondaryImg} alt="Coach hire leeds" />
                  </picture>
                </div>
                <div className="block-about-us__content-right">
                  <p>Tetley's Coaches is Leeds' oldest, established coach operator and our successful operation continues to provide a comprehensive range of quality and competitively priced transport solutions for any event, whether locally and nationwide.</p>
                  <p>Wherever your destination, near or far, Leeds, Lytham or London; whatever your hire, be it private, corporate or contract, we have the coach to meet your requirement, your expectation and your budget.</p>
                </div>

              </div>
              <p style={{ textAlign: 'center' }}>
                <a className='btn btn-grow btn-quote' href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">
                  <span className='btn-quote__text'>Get quote</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="block-services">
          <div className="body gutter">
            <div className='block-services__heading' data-aos='fade-left' data-aos-duration="300">
              <h2>Coach hire quote</h2>
              <h3>Coach hire for any occasion</h3>
            </div>
            <div className="block-services__primary">

              <div className="block-services__primary-text">
                <p>We operate from central Leeds, close to all major motorways and provide competitively priced coach hire in all areas of <Link to="/coach-hire-wakefield/">Wakefield</Link>, <Link to="/coach-hire-bradford/">Bradford</Link>, <Link to="/coach-hire-halifax/">Halifax</Link>, <Link to="/coach-hire-huddersfield/">Huddersfield</Link>, <Link to="/coach-hire-harrogate/">Harrogate</Link> and <Link to="/coach-hire-york/">York</Link>.</p>
                <p>Our friendly and experienced sales team will be happy to discuss your itinerary and tailor your transport requirements and provide a no-obligation, no-pressure competitive quote.</p>
                <p>Complete our <a href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">online quote form</a> for a speedy no-obligation emailed quote or call 0113 2762276.</p>
              </div>

              <div className="block-services__primary-img">
                <picture>

                  <source media="(max-width: 499px)" type="image/webp" srcSet={coachServicesImgMobWebp} />
                  <source media="(max-width: 499px)" type="image/jpg" srcSet={coachServicesImgMob} />
                  <source media="(min-width: 500px)" type="image/webp" srcSet={coachServicesImgWebp} />
                  <source media="(min-width: 500px)" type="image/jpg" srcSet={coachServicesImg} />
                  <img src={coachServicesImg} alt="Coach company leeds" />
                </picture>
              </div>
            </div>

            <div className="block-services__info" data-aos="fade-right" data-aos-duration="600">
              <img className="block-services__info-icon" src={smileSvg} alt='' />
              <div className="block-services__info-text">
                <h4>Unbeaten value</h4>
                <p>If we provide a quote and you can find a similar quality coach cheaper we'll do our best to beat it.</p>
              </div>
            </div>
            <div id="services" className="block-services__list-parent">
              <ul className="block-services__list">
                <li><Link to="/coach-hire-services/airport-transfers/" title="Coach Hire For Airport Transfers">Airport Transfers</Link></li>
                <li><Link to="/coach-hire-services/club-pub-trips/" title="Coach Hire For Club and Pub Trips">Club and Pub Trips</Link></li>
                <li><Link to="/coach-hire-services/coast-country-day-trips/" title="Coach Hire For Coast and Country Day Trips">Coast and Country Day Trips</Link></li>
                <li><Link to="/coach-hire-services/college-university-trips/" title="Coach Hire For College and University Trips">College and University Trips</Link></li>
                <li><Link to="/coach-hire-services/community-groups/" title="Coach Hire For Community Groups">Community Groups</Link></li>
                <li><Link to="/coach-hire-services/contract-coach-hire/" title="Coach Hire For Contract Work">Contract Work</Link></li>
                <li><Link to="/coach-hire-services/corporate-events-coach-hire/" title="Coach Hire For Corporate and Events">Corporate and Events</Link></li>
                <li><Link to="/coach-hire-services/day-at-the-races/" title="Coach Hire For A Day At The Races">Day At The Races</Link></li>
              </ul>
            </div>
            <div className="block-services__list-parent">
              <ul className="block-services__list">
                <li><Link to="/coach-hire-services/wheelchair-disabled-access-coach-hire/" title="Wheelchair friendly and disabled access coach hire">Disabled access coach hire</Link></li>
                <li><Link to="/coach-hire-services/funerals/" title="Coach Hire For Funerals">Funerals</Link></li>
                <li><Link to="/coach-hire-services/group-tours/" title="Coach Hire For Group Tours">Group Tours</Link></li>
                <li><Link to="/coach-hire-services/school-trips/" title="Coach Hire For School Trips">School Trips</Link></li>
                <li><Link to="/coach-hire-services/sporting-events/" title="Coach Hire For Sporting Events">Sporting Events</Link></li>
                <li><Link to="/coach-hire-services/theatre-concerts-cultural-events/" title="Coach Hire For Theatre, Concerts &amp; Cultural Events">Theatre, Concerts &amp; Cultural Events</Link></li>
                <li><Link to="/coach-hire-services/theme-parks/" title="Coach Hire For Theme Parks">Theme Parks</Link></li>
                <li><Link to="/coach-hire-services/weddings-engagements-parties-nights-out/" title="Coach Hire For Weddings, Engagements, Parties and Nights Out">Weddings, Engagements, Parties and Nights Out</Link></li>
              </ul>
            </div>

          </div>
        </div>

        <div className="block-fleet" id="fleet">
          <div className="body gutter">
            <h2>Our fleet</h2>
            <p>We own and operate our own fleet of 60 plus vehicles which comprises 16 seat minibuses, 33 seat midi-buses, 49, 57 and 70 seat coaches plus 2 off 48 seat wheelchair accessible coaches.</p>
            <div className="fleet">
              <div className="fleet-bus" data-aos='fade-up' data-aos-delay="100" data-aos-duration="300">
                <Link className="fleet-bus__link" to="/fleet/minibus-hire/">
                  <span className="fleet-bus__border"></span>
                  <picture className="fleet-bus__img">
                    <source type="image/webp" srcSet={fleet16SeatImgWebp} />
                    <img src={fleet16SeatImg} alt="Minibus hire leeds" />
                  </picture>

                  <span className="fleet-bus__title">16 seat minibus</span>
                </Link>
              </div>
              <div className="fleet-bus" data-aos='fade-up' data-aos-delay="150" data-aos-duration="300">
                <Link className="fleet-bus__link" to="/fleet/33-seat-midi-bus/">
                  <span className="fleet-bus__border"></span>
                  <picture className="fleet-bus__img">
                    <source type="image/webp" srcSet={fleet33SeatImgWebp} />
                    <img src={fleet33SeatImg} alt="33 seat coach hire leeds" />
                  </picture>
                  <span className="fleet-bus__title">33/35 seat midi-bus</span>
                </Link>
              </div>
              <div className="fleet-bus" data-aos='fade-up' data-aos-delay="200" data-aos-duration="300">
                <Link className="fleet-bus__link" to="/fleet/49-seat-coach/">
                  <span className="fleet-bus__border"></span>
                  <picture className="fleet-bus__img">
                    <source type="image/webp" srcSet={fleet49SeatImgWebp} />
                    <img src={fleet49SeatImg} alt="49-57 seat coach hire leeds" />
                  </picture>
                  <span className="fleet-bus__title">49-57 seat coach</span>
                </Link>
              </div>
              <div className="fleet-bus" data-aos='fade-up' data-aos-delay="250" data-aos-duration="300">
                <Link className="fleet-bus__link" to="/fleet/70-seat-coach/">
                  <span className="fleet-bus__border"></span>
                  <picture className="fleet-bus__img">
                    <source type="image/webp" srcSet={fleet70SeatImgWebp} />
                    <img src={fleet70SeatImg} alt="70 seat coach hire leeds" />
                  </picture>
                  <span className="fleet-bus__title">70 seat school coach</span>
                </Link>
              </div>
            </div>
            <p>When you hire a vehicle from Tetley's, you can be re-assured that you're booking with a reputable company that owns its own vehicles and employs its own drivers. Your safety is our priority.</p>
            <p>All coaches are fully seat-belted and many have WC, radio with USB connection, air-conditioning and on-board WIFI. In the interest of customer safety, all coaches, midi-buses, minibuses and buses are fitted with Greenroad tracking, driving behaviour monitoring hardware and CCTV.</p>
            <p style={{ margin: 0 }}>
              <Link className='btn btn-grow btn-fleet' to="/fleet/">
                <span className='btn-fleet__text'>View fleet</span>
                <img className='btn-fleet__icon' src={arrowSvg} alt="" />
              </Link>
            </p>
          </div>
        </div>

        <div className="block-review">
          <div className="body gutter">
            <h2>Reviews</h2>
            <div className="reviews">
              <div className="review" data-aos='fade-up' data-aos-delay="100" data-aos-duration="300">
                <p className="review-rating">
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="200" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="500" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="600" className='star' src={starSvg} alt='Star' />
                </p>
                <p className="review-quote">First time we have used Tetley's and won't be the last. We received a competitive price, picked up on time and driver was helpful &amp; friendly.</p>
                <p className="review-quote">I have no hesitation in recommending this company.</p>
                <p className="review-author">Tina G.</p>
              </div>
              <div className="review" data-aos='fade-up' data-aos-delay="200" data-aos-duration="300">
                <p className="review-rating">
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="200" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="500" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="600" className='star' src={starSvg} alt='Star' />
                </p>
                <p className="review-quote">West Leeds Walking group have been using Tetley's coaches for 15 years and have always found the service to be excellent.</p>
                <p className="review-quote">The drivers are always friendly, courteous and helpful.</p>
                <p className="review-author">Bill S.</p>
              </div>
              <div className="review" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <p className="review-rating">
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="200" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="500" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="600" className='star' src={starSvg} alt='Star' />
                </p>
                <p className="review-quote">We used Tetley's coaches for the first time for our company summer event in Birmingham. Great price and service, punctual and friendly driver.</p>
                <p className="review-quote">Would recommend and would use again!</p>
                <p className="review-author">April L.</p>
              </div>
            </div>
            <p style={{ margin: 0 }}>
              <a className='btn btn-review btn-grow' href="https://www.google.com/search?q=tetleys+coaches&ie=UTF-8#lrd=0x48795c38882c4475:0x9fc9a7dfa886a150,1,,," target="_blank" rel="noopener noreferrer">
                <span className='btn-review__text'>More reviews</span>
                <img className='btn-review__icon' src={arrowSvg} alt="" />
              </a>
            </p>
          </div>
        </div>

        <div className="block-others">
          <div className="body gutter">
            <div className="block-others__promo">
              <h2 data-aos='fade-left' data-aos-duration="300">Corporate Staff Transport</h2>
              <div data-aos='fade-in' data-aos-delay="100" data-aos-duration="300">
                <picture className="block-others__img">
                  <source type="image/webp" srcSet={CorporateCoachImgWebp} />
                  <img src={CorporateCoachImg} alt="Corporate coach hire" />
                </picture>
                <p>We are extremely proud to provide eleven coaches daily to transport staff for Capita, Lowell, DWP, BAE and Newday.</p>
                <p>We are an approved supplier to both the University of Leeds and Leeds Beckett University.</p>
                <Link className='btn btn-grow btn-others' to="/contact/">
                  <span className='btn-others__text'>Enquire</span>
                  <img className='btn-others__icon' src={arrowSvg} alt="" />
                </Link>
              </div>
            </div>
            <div className="block-others__promo no-margin-mob">
              <h2 data-aos='fade-right' data-aos-duration="300">Wakefield Free City Bus</h2>
              <div data-aos='fade-in' data-aos-delay="200" data-aos-duration="300">
                <picture className="block-others__img">
                  <source type="image/webp" srcSet={wakefieldBusImgWebp} />
                  <img src={wakefieldBusImg} alt="Wakefield free bus" />
                </picture>
                <p>We also operate the Wakefield Free city bus, providing residents and visitors an alternative to using the car around the city centre.</p>
                <p>The bus travels on a circular route linking the two train stations, bus station and main shopping areas.</p>
                <Link className='btn btn-grow btn-others' to="/news/wakefield-free-bus-relaunch/">
                  <span className='btn-others__text'>Read more</span>
                  <img className='btn-others__icon' src={arrowSvg} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default IndexPage
